import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DefaultLayout from './components/defaultLayout/DefaultLayout';
import { AuthContext } from './context/AuthContext';
import { UploaderContext } from './context/UploaderContext';
import { DataContext } from './context/DataContext';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser, fetchTranslationGroup } from './store/userSlice';
import { fetchChats } from './store/chatSlice';
import { setShowUnreadIndicator } from './store/uiSlice';
import { incrementUnreadMessages } from './store/unreadMessagesSlice';
import { notification } from 'antd';

import { useAuth } from './hooks/auth.hook';
import { useRoutes } from './routes';
import { connectSocket, on, removeAllListeners } from './socket';

import 'antd/dist/reset.css';

const App = () => {
  const [contextUploader, setContextUploader] = useState(false);
  const [contextData, setContextData] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const { token, userId, login, logout } = useAuth();
  const isAuth = !!token;
  const userInfo = useSelector(state => state.userInfo.userState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  connectSocket();
  let routes = useRoutes(isAuth);

  // useEffect(() => {
  //   routes = useRoutes(!!userInfo.token)
  // }, [userInfo]);

  useEffect(() => {
      dispatch(fetchUser());
      dispatch(fetchTranslationGroup());
  }, []);

  useEffect(() => {
    if (userId){
      dispatch(fetchChats(userId));
      on('message_v_0.4_newMessage', (data) => {
        if (data.senderID !== userId.toString()) {
          dispatch(incrementUnreadMessages({ chatId: data.chatID }));
          dispatch(setShowUnreadIndicator(true));
          if (!location.pathname.startsWith('/messages')) {
            const notificationKey = `open${Date.now()}`;
            api.info({
              message: `Чат "${data.chatName}"`,
              description: data.text,
              placement: "topRight",
              key: notificationKey,
              duration: 3,
              onClick: () => {
                navigate(`/messages?${data.chatID}`);
                api.destroy(notificationKey);
              }
            })
          }
        }
      });
    }
    return () => {
      removeAllListeners('message_v_0.4_newMessage');
    };
  }, [userId, location.pathname]);

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        userId,
        isAuth,
      }}
    >
      {contextHolder}
      <UploaderContext.Provider value={[contextUploader, setContextUploader]}>
        <DataContext.Provider value={[contextData, setContextData]}>
          <DefaultLayout>{routes}</DefaultLayout>
        </DataContext.Provider>
      </UploaderContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
