import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchUser = createAsyncThunk(
  'userSlice/fetchUser',
  async function (_, { rejectWithValue }) {
    try {
      const response = await fetch(`/api/users/getCurrentUser`, {
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.userData).token}`,
        }
      })
        .then((res) => res.json());

      if (!response) {
        throw new Error('Server Error');
      }

      return response;
    } catch (error) {
      console.log('errorMessageUser', error.message);
      return rejectWithValue(error.message);
    }

  }
);

export const fetchTranslationGroup = createAsyncThunk(
  'userSlice/fetchTranslationGroup',
  async function (_, { rejectWithValue }) {
    try {
      const response = fetch(`/api/group_translation/get_active_group`, {
        headers: {
          authorization: `Bearer ${JSON.parse(localStorage.userData).token}`,
        }
      })
        .then((res) => res.json());

      if (!response) {
        throw new Error('Server Error');
      }
      return response;
    } catch (error) {
      console.log('errorFetchTranslationGroup', error.message);
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    userState: [],
    isAdmin: false,
    translationGroup: {room_id: null},
    translationGroupDBId: null,
    streamingDevices: [],
    status: null,
    error: null,
  },
  reducers: {
    getInfo(state, action) {
      return state.userState;
    },
    getAdminInfo(state, action) {
      return state.isAdmin;
    },
    getTranslationGroup(state, action) {
      return state.translationGroup;
    },
    getTranslationGroupDBId(state, action) {
      return state.translationGroupDBId;
    }
  },
  extraReducers: {
    [fetchUser.pending]: (state, action) => {
      state.status = 'loading';
      state.error = null;
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.isAdmin = action.payload.sub_id === 0 ? true : false;
      state.userState = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
    [fetchTranslationGroup.pending]: (state, action) => {
      state.status = 'loading';
      state.error = null;
    },
    [fetchTranslationGroup.fulfilled]: (state, action) => {
      state.status = 'resolved';
      state.translationGroup = action.payload.room_id;
      state.streamingDevices = action.payload.devices;
      state.translationGroupDBId = action.payload.room_db_id;
    },
    [fetchTranslationGroup.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    },
  }
});

export const { getInfo, getAdminInfo, getTranslationGroup } = userSlice.actions;
export default userSlice.reducer;